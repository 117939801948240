<template>
  <div class="chat">
    <div
      :class="[
        'message',
        message.role === 'support' ? 'message--support' : 'message--user'
      ]"
      v-for="message in messages"
      :key="message.id"
    >
      <h6 class="message__name">{{ message.author }}</h6>
      <quill-editor
        class="message__text"
        :content="cleanMessage(message.text)"
        :options="messageOption"
        :disabled="true"
      ></quill-editor>
      <div class="message__attachments attachments">
        <div
          class="attachments__item"
          v-for="attachment in message.attachments"
          :key="attachment.id"
          @click="loadFile({ id: attachment.id, name: attachment.name })"
        >
          <div
            class="attachments__img"
            v-if="
              attachment.type === 'jpg' ||
                attachment.type === 'png' ||
                attachment.type === 'jpeg'
            "
          >
            <img
              :src="url + 'api/attachments/' + attachment.id"
              height="auto"
              width="100%"
              alt=""
            />
            <p>{{ attachment.name }}</p>
          </div>
          <div class="attachments__wrapper" v-else>
            <p class="attachments__name">
              {{ attachment.name.split(".")[0] }}
            </p>
            <p class="attachments__type">{{ attachment.type }}</p>
          </div>
        </div>
      </div>

      <p class="message__date">
        {{ message.created_at + " #" + $route.params.id }}
      </p>

      <button
        class="message__edit"
        v-if="message.role === 'support'"
        @click="editMessage(message.id, message.text)"
      >
        <svg
          version="1.1"
          width="512"
          height="512"
          x="0"
          y="0"
          viewBox="0 0 383.947 383.947"
          style="enable-background:new 0 0 512 512"
          xml:space="preserve"
          class=""
        >
          <g>
            <g xmlns="http://www.w3.org/2000/svg">
              <g>
                <g>
                  <polygon
                    points="0,303.947 0,383.947 80,383.947 316.053,147.893 236.053,67.893    "
                    fill="#646464"
                    data-original="#000000"
                    style=""
                  />
                  <path
                    d="M377.707,56.053L327.893,6.24c-8.32-8.32-21.867-8.32-30.187,0l-39.04,39.04l80,80l39.04-39.04     C386.027,77.92,386.027,64.373,377.707,56.053z"
                    fill="#646464"
                    data-original="#000000"
                    style=""
                  />
                </g>
              </g>
            </g>
            <g xmlns="http://www.w3.org/2000/svg"></g>
            <g xmlns="http://www.w3.org/2000/svg"></g>
            <g xmlns="http://www.w3.org/2000/svg"></g>
            <g xmlns="http://www.w3.org/2000/svg"></g>
            <g xmlns="http://www.w3.org/2000/svg"></g>
            <g xmlns="http://www.w3.org/2000/svg"></g>
            <g xmlns="http://www.w3.org/2000/svg"></g>
            <g xmlns="http://www.w3.org/2000/svg"></g>
            <g xmlns="http://www.w3.org/2000/svg"></g>
            <g xmlns="http://www.w3.org/2000/svg"></g>
            <g xmlns="http://www.w3.org/2000/svg"></g>
            <g xmlns="http://www.w3.org/2000/svg"></g>
            <g xmlns="http://www.w3.org/2000/svg"></g>
            <g xmlns="http://www.w3.org/2000/svg"></g>
            <g xmlns="http://www.w3.org/2000/svg"></g>
          </g>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { url } from "@/main";
import { eventBus } from "@/main";

export default {
  name: "TicketChat",
  data() {
    return {
      messageOption: {
        theme: "bubble",
        modules: {
          toolbar: false
        },
        formats: [
          "background",
          "bold",
          "color",
          "font",
          "code",
          "italic",
          "link",
          "size",
          "strike",
          "underline",
          "blockquote",
          "header",
          "indent",
          "list",
          "align",
          "direction",
          "code-block",
          "formula"
        ]
      },

      url: url
    };
  },

  props: {
    messages: {
      type: Array,
      required: true
    }
  },

  methods: {
    ...mapActions({
      loadFile: "message/loadFile"
    }),

    editMessage(id, text) {
      eventBus.$emit("editMessage", {
        id: id,
        text: text
      });
    },

    cleanMessage(message) {
      return this.$sanitize(message);
    }
  }
};
</script>

<style lang="scss">
.chat {
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column-reverse;
  word-break: break-word;
  padding: 0 40px 0 50px;

  .message {
    padding: 16px 20px 12px 16px;
    border-radius: 6px;
    margin-top: 24px;
    max-width: 70%;
    position: relative;
    &__name {
      font-size: 14px;
      font-weight: 500;
      color: $third;
      margin-bottom: 8px;
    }
    &__text {
      .ql-editor {
        padding: 0;
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 12px;
        font-family: "Rubik", sans-serif;
      }

      a {
        color: white;
      }
    }
    &__date {
      font-size: 10px;
      line-height: 12px;
    }

    &__edit {
      position: absolute;
      right: 20px;
      top: 40px;
      background: transparent;
      border: none;
      outline: none;

      svg {
        height: 12px;
        width: auto;
      }
    }
  }
  .message--support {
    background: $main;
    margin-left: auto;
    .message__name {
      color: $third;
    }
    .message__text {
      .ql-editor {
        color: #f3f7ff;
        padding-right: 28px;
      }
    }
    .message__date {
      color: #749eff;
    }

    .attachments {
      &__item {
        background-color: #f4f5f9;
      }
    }

    .message__edit {
      svg {
        polygon,
        path {
          fill: #f4f5f9;
        }
      }
    }
  }
  .message--user {
    background: #f4f5f9;
    margin-right: auto;
    .message__name {
      color: $label-main;
    }
    .message__text {
      .ql-editor {
        color: $label-second;
      }
    }
    .message__date {
      color: $label-third;
    }

    .attachments {
      &__item {
        background-color: $main;
      }
    }
  }

  .attachments {
    margin-bottom: 10px;
    display: flex;

    &__item {
      height: 64px;
      width: 64px;
      overflow: hidden;
      border-radius: 8px;
      text-align: center;
      cursor: pointer;
      margin-right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      p {
        white-space: nowrap;
      }
    }

    &__img {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      p {
        position: absolute;
        margin: auto;
        left: 2px;
        max-width: 60px;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 10px;
        color: white;
        text-align: center;
        width: 100%;
        background-color: rgba(#212121, 0.65);
        border-radius: 2px;
        padding: 1px 2px;
      }
    }

    &__wrapper {
      padding: 6px;
    }

    &__name {
      font-size: 10px;
      max-width: 52px;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &__type {
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 600;
    }
  }
}

//}
</style>
