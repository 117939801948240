<template>
  <div class="ticket">
    <img
      class="loading"
      v-if="loadingTicket === 'LOADING'"
      src="../assets/loading.svg"
      alt=""
    />
    <div class="ticket-info" v-if="loadingTicket === 'SUCCESS' && ticket">
      <div class="ticket-info__title">
        <div
          :class="[
            'title-comments',
            commentsVisible ? ' title-comments--active' : '',
          ]"
        >
          <button @click="openComments">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 1.5C6.20156 1.5 1.5 6.20156 1.5 12C1.5 17.7984 6.20156 22.5 12 22.5C17.7984 22.5 22.5 17.7984 22.5 12C22.5 6.20156 17.7984 1.5 12 1.5ZM12 20.7188C7.18594 20.7188 3.28125 16.8141 3.28125 12C3.28125 7.18594 7.18594 3.28125 12 3.28125C16.8141 3.28125 20.7188 7.18594 20.7188 12C20.7188 16.8141 16.8141 20.7188 12 20.7188Z"
                fill="#221122"
                fill-opacity="0.4"
              />
              <path
                d="M10.875 7.875C10.875 8.17337 10.9935 8.45952 11.2045 8.6705C11.4155 8.88147 11.7016 9 12 9C12.2984 9 12.5845 8.88147 12.7955 8.6705C13.0065 8.45952 13.125 8.17337 13.125 7.875C13.125 7.57663 13.0065 7.29048 12.7955 7.0795C12.5845 6.86853 12.2984 6.75 12 6.75C11.7016 6.75 11.4155 6.86853 11.2045 7.0795C10.9935 7.29048 10.875 7.57663 10.875 7.875ZM12.5625 10.5H11.4375C11.3344 10.5 11.25 10.5844 11.25 10.6875V17.0625C11.25 17.1656 11.3344 17.25 11.4375 17.25H12.5625C12.6656 17.25 12.75 17.1656 12.75 17.0625V10.6875C12.75 10.5844 12.6656 10.5 12.5625 10.5Z"
                fill="#221122"
                fill-opacity="0.4"
              />
            </svg>
          </button>
        </div>

        <div class="title-user">
          <p v-if="ticket.client.first_name" class="title-user__name">
            {{ ticket.client.first_name }}
          </p>
        </div>

        <div v-if="ticket.client.phone" class="title-user">
          <p class="title-user__phone">
            {{ ticket.client.phone }}
          </p>
        </div>

        <div class="title-type">
          <p>{{ ticket.theme.name }}</p>
        </div>

        <ticket-selects :ticket="ticket" :loadTicket="loadTicketRequest" />
      </div>

      <ticket-chat :messages="messages" />

      <ticket-editor
        :status="ticket.status"
        :editMessageID="editMessageID"
        :showModal="showModal"
      />
    </div>

    <transition name="slide">
      <ticket-details
        :id="ticket.id"
        :userID="ticket.client.id"
        v-if="commentsVisible && loadingTicket === 'SUCCESS'"
      />
    </transition>

    <transition name="fade">
      <div class="preset-edit" v-if="modal">
        <!-- Modal content -->
        <div
          id="modal"
          class="preset-edit__content"
          v-click-outside="onClickOutside"
        >
          <span class="preset-edit__close" @click="modal = false">
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.19998 0.730891L6.99998 5.53089L11.8 0.745437C11.8945 0.649276 12.0079 0.573771 12.1331 0.523698C12.2583 0.473624 12.3925 0.450071 12.5273 0.454528C12.7917 0.471639 13.0408 0.584406 13.2282 0.77177C13.4156 0.959135 13.5283 1.20829 13.5454 1.47271C13.5467 1.60272 13.5216 1.73164 13.4716 1.85166C13.4216 1.97167 13.3478 2.08028 13.2545 2.17089L8.43998 6.99998L13.2545 11.8291C13.4436 12.0123 13.5483 12.2654 13.5454 12.5273C13.5283 12.7917 13.4156 13.0408 13.2282 13.2282C13.0408 13.4156 12.7917 13.5283 12.5273 13.5454C12.3925 13.5499 12.2583 13.5263 12.1331 13.4763C12.0079 13.4262 11.8945 13.3507 11.8 13.2545L6.99998 8.46907L2.21452 13.2545C2.12001 13.3507 2.00658 13.4262 1.8814 13.4763C1.75621 13.5263 1.622 13.5499 1.48725 13.5454C1.2179 13.5314 0.963286 13.4181 0.772566 13.2274C0.581847 13.0367 0.468542 12.7821 0.454521 12.5127C0.453205 12.3827 0.478312 12.2538 0.528318 12.1338C0.578325 12.0137 0.652189 11.9051 0.745431 11.8145L5.55998 6.99998L0.730885 2.17089C0.640269 2.07906 0.569093 1.9699 0.521609 1.84994C0.474126 1.72998 0.451309 1.60168 0.454521 1.47271C0.471633 1.20829 0.584399 0.959135 0.771764 0.77177C0.959129 0.584406 1.20828 0.471639 1.4727 0.454528C1.60641 0.448177 1.73999 0.469439 1.86511 0.516987C1.99024 0.564534 2.10423 0.637349 2.19998 0.730891Z"
                fill="#221122"
              />
            </svg>
          </span>

          <div class="preset-edit__title">
            <h3>Готовые ответы</h3>
          </div>

          <div class="preset-edit__main">
            <div class="preset-edit__preset-edit">
              <h4>
                {{ presetEdit.id ? "Редактирование ответа" : "Новый ответ" }}
              </h4>

              <input
                type="text"
                v-model="presetEdit.name"
                placeholder="Название"
              />

              <quill-editor
                class="editor"
                v-model="presetEdit.content"
                :options="editorOptions"
              >
              </quill-editor>

              <div class="preset-edit__btns">
                <button
                  class="preset-edit__btn"
                  :class="
                    !presetEdit.name || !presetEdit.content
                      ? 'preset-edit__btn--disabled'
                      : ''
                  "
                  :disabled="!presetEdit.name || !presetEdit.content"
                  @click="editPresetRequest"
                >
                  Сохранить ответ
                </button>

                <button
                  class="preset-edit__btn preset-edit__btn--red"
                  @click="resetPreset"
                >
                  Очистить
                </button>
              </div>
            </div>

            <div class="preset-edit__presets">
              <div
                :class="[
                  'preset-edit__preset',
                  item.delete ? 'preset-edit__preset--deleted' : '',
                ]"
                v-for="item in presets"
                :key="item.id"
              >
                <p @click="selectPreset(item.id)">{{ item.title }}</p>

                <span
                  class="close"
                  @click="deletePresetRequest(item.id)"
                ></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import TicketDetails from "@/components/ticket/TicketDetails";
import TicketSelects from "@/components/ticket/TicketSelects";
import TicketChat from "@/components/ticket/TicketChat";
import TicketEditor from "@/components/ticket/TicketEditor";
import { editorOptions } from "@/components/mixins/editorOptions.mixin";

export default {
  name: "TicketsSupport",
  mixins: [editorOptions],
  components: {
    TicketDetails,
    TicketSelects,
    TicketChat,
    TicketEditor,
  },

  data() {
    return {
      modal: false,
      editMessageID: "",

      presetEdit: {
        id: "",
        name: "",
        content: "",
      },
    };
  },

  watch: {
    "$route.params.id": function() {
      if (Number.isInteger(parseInt(this.$route.params.id))) {
        this.editMessageID = "";
        this.loadTicketRequest(this.$route.params.id);
        this.$echo
          .channel(`tickets.${this.$route.params.id}`)
          .listen("MessageCreate", () => {
            if (this.$route.params.id) {
              this.loadMessages({ id: this.$route.params.id });
            }
          })
          .listen("MessageUpdate", () => {
            if (this.$route.params.id) {
              this.loadMessages({ id: this.$route.params.id });
            }
          });
      }
    },
  },

  computed: {
    ...mapState({
      loadingTicket: (state) => state.ticket.loadingTicket,
      ticket: (state) => state.ticket.ticket,
      commentsVisible: (state) => state.commentsVisible,
      messages: (state) => state.message.messages,
      presets: (state) => state.message.presets,
    }),

    ...mapGetters({
      getPresetById: "message/getPresetById",
    }),
  },

  methods: {
    ...mapMutations({
      setCommentsState: "SET_COMMENTS_STATE",
      addMessage: "message/ADD_MESSAGE",
      setLoading: "ticket/SET_LOADING",
    }),

    ...mapActions({
      loadTicket: "ticket/loadTicket",
      loadMessages: "message/loadMessages",
      createPreset: "message/createPreset",
      editPreset: "message/editPreset",
      deletePreset: "message/deletePreset",
      loadPresets: "message/loadPresets",
    }),

    showModal() {
      this.modal = true;
    },

    selectPreset(id) {
      this.presetEdit.id = this.getPresetById(id).id;
      this.presetEdit.name = this.getPresetById(id).title;
      this.presetEdit.content = this.getPresetById(id).text;
    },

    editPresetRequest() {
      if (this.presetEdit.id) {
        this.editPreset(this.presetEdit).then(() => {
          this.loadPresets();
        });
      } else {
        this.createPreset(this.presetEdit).then(() => {
          this.presetEdit.name = "";
          this.presetEdit.content = "";
          this.loadPresets();
        });
      }
    },

    deletePresetRequest(id) {
      this.deletePreset(id).then(() => {
        this.loadPresets();
      });
    },

    resetPreset() {
      this.presetEdit.id = "";
      this.presetEdit.name = "";
      this.presetEdit.content = "";
    },

    openComments() {
      this.setCommentsState(!this.commentsVisible);
    },

    onClickOutside(event) {
      if (event.target.id !== "modal") {
        this.modal = false;
        this.presetEdit.id = "";
        this.presetEdit.name = "";
        this.presetEdit.content = "";
      }
    },

    loadTicketRequest(id) {
      if (Number.isInteger(parseInt(id))) {
        this.setLoading({ type: "ticket", value: "LOADING" });
        this.loadMessages({ id: id }).then(() => {
          this.loadTicket({ id: id });
          this.loadPresets();
        });
      }
    },
  },

  mounted() {
    if (Number.isInteger(parseInt(this.$route.params.id))) {
      this.loadTicketRequest(this.$route.params.id);
      this.$echo
        .channel(`tickets.${this.$route.params.id}`)
        .listen("MessageCreate", () => {
          if (this.$route.params.id) {
            this.loadMessages({ id: this.$route.params.id });
          }
        })
        .listen("MessageUpdate", () => {
          if (this.$route.params.id) {
            this.loadMessages({ id: this.$route.params.id });
          }
        });
    }
  },
};
</script>

<style lang="scss">
.ticket {
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  justify-content: flex-end;

  .ticket-info {
    background: #ffffff;
    width: 100%;
    overflow: hidden;
    position: relative;
    max-height: 100vh;
    display: flex;
    flex-direction: column;

    &__title {
      padding: 32px 40px 40px;
      border-bottom: 1px solid #eeeeee;
      position: relative;

      .title-comments {
        position: absolute;
        right: 50px;
        top: calc(50% - 20px);

        button {
          height: 40px;
          width: 40px;
          background: #f9f9f9;
          border-radius: 4px;
          border: none;
          outline: none;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          transition: all 0.1s linear;

          &:hover {
            box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
            transform: scale(1.05);
          }

          &:active {
            transform: scale(1);
          }
        }
      }

      .title-comments--active {
        button {
          background: #3870ff;
          transform: scale(1);

          svg {
            path {
              fill: #ffffff;
              fill-opacity: 1;
            }
          }
        }
      }

      .title-user {
        display: flex;
        margin-bottom: 12px;

        &__name,
        &__email,
        &__phone {
          font-size: 18px;
        }

        &__name {
          margin-right: 8px;
          font-weight: 500;
        }

        &__email {
          color: $label-third;
        }
      }

      .title-type {
        margin-bottom: 18px;

        p {
          font-size: 18px;
          line-height: 21px;
          max-width: 85%;
          color: $label-second;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
        }
      }
    }

    &__chat {
      padding: 0 40px 10px 50px;
      overflow-y: scroll;
      scroll-behavior: smooth;
      word-break: break-word;

      @media (max-width: 768px) {
        padding: 0 20px;
      }
    }
  }

  .preset-edit {
    position: fixed;
    z-index: 9999999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);

    &__content {
      background-color: #ffffff;
      margin: 5% auto;
      padding: 42px 32px 32px 32px;
      border-radius: 8px;
      width: 675px;
      position: relative;
    }

    &__close {
      opacity: 0.4;
      cursor: pointer;
      position: absolute;
      top: 42px;
      right: 32px;
    }

    &__title {
      margin-bottom: 32px;

      h3 {
        font-size: 18px;
        font-weight: 500;
        color: $label-main;
      }
    }

    &__preset-edit {
      display: flex;
      flex-direction: column;

      h4 {
        font-size: 18px;
        color: $label-second;
        margin-bottom: 24px;
        font-weight: 400;
      }

      input {
        background: #f5f6fa;
        border-radius: 8px;
        border: none;
        height: 48px;
        margin-bottom: 16px;
        padding: 0 16px 0 24px;
        outline: none;
        color: $label-main;
      }
    }

    &__btns {
      display: flex;
      justify-content: space-between;
    }

    &__btn {
      height: 42px;
      border: 2px solid #3870ff;
      box-sizing: border-box;
      border-radius: 8px;
      background-color: #ffffff;
      color: $main;
      padding: 0 42px;
      font-size: 14px;
      margin-top: 24px;
      outline: none;
      cursor: pointer;
      transition: background-color 0.05s linear;

      &:hover {
        background-color: #ebf1ff;
      }

      &:active {
        background-color: #ffffff;
      }

      &--disabled {
        opacity: 0.7;
        cursor: default;

        &:hover {
          background-color: #ffffff;
        }
      }

      &--red {
        border: 2px solid #ffe1e1;
        color: $label-main;

        &:hover {
          background-color: #ffe1e1;
        }
      }
    }

    &__presets {
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      margin-top: 35px;
    }

    &__preset {
      height: 40px;
      background: #f4f5f9;
      border-radius: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 16px 15px 20px;
      margin-right: 10px;
      margin-bottom: 8px;

      p {
        color: $label-main;
        font-size: 14px;
        margin-right: 24px;
        cursor: pointer;
      }

      span {
        display: block;
        width: 12px;
        height: 12px;
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer;
      }

      .close {
        background-image: url("../assets/icons/delete.svg");

        &:hover {
          background-size: 12px 12px;
        }
      }

      .restore {
        background-image: url("../assets/icons/restore.svg");

        &:hover {
          transform: rotate(45deg);
        }
      }

      &--deleted {
        background: #ffe1e1;
      }
    }
  }

  .quill-editor.editor {
    background: #f5f6fa;
    border-radius: 4px;
    padding: 10px 16px;
  }
  .ql-toolbar.ql-snow {
    border: none;
    border-bottom: 1px solid #ebecf2;
  }
  .ql-container.ql-snow {
    border: none;
  }
}
</style>
