<template>
  <div class="title-selects">
    <div class="title-selects__item title-selects__item--first">
      <label for="source_id">
        <select
          name="source_id"
          id="source_id"
          @change="handleChange('source_id')"
        >
          <option value="">Не назначен</option>
          <option
            :value="source.id"
            v-for="source in sources"
            :key="source.id"
            :selected="source.id === ticket.source.id"
          >
            {{ source.name }}
          </option>
        </select>

        <svg
          width="6"
          height="4"
          viewBox="0 0 6 4"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M1 1L3 3L5 1" stroke="black" />
        </svg>
      </label>
    </div>

    <div class="title-selects__item title-selects__item--second">
      <label for="status_id">
        <select
          name="status_id"
          id="status_id"
          @change="handleChange('status_id')"
        >
          <option value="">Не назначен</option>
          <option
            :value="status.status"
            v-for="status in statuses"
            :key="status.status"
            :selected="status.status === ticket.status"
          >
            {{ status.status }}
          </option>
        </select>

        <svg
          width="6"
          height="4"
          viewBox="0 0 6 4"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M1 1L3 3L5 1" stroke="black" />
        </svg>
      </label>
    </div>
    <div class="title-selects__item title-selects__item--third">
      <label for="support_id">
        <select
          name="support_id"
          id="support_id"
          @change="handleChange('support_id')"
        >
          <option value="">Не назначен</option>
          <option
            :value="user.id"
            v-for="user in users"
            :key="user.id"
            :selected="user.id === (ticket.support ? ticket.support.id : '')"
          >
            {{ user.name }}
          </option>
        </select>

        <svg
          width="6"
          height="4"
          viewBox="0 0 6 4"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M1 1L3 3L5 1" stroke="black" />
        </svg>
      </label>
    </div>

    <button class="title-selects__confirm" @click="confirmUpdate">
      Подтвердить
    </button>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "TicketSelects",
  props: {
    ticket: {
      type: Object,
      required: true
    },
    loadTicket: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      params: {}
    };
  },

  computed: {
    ...mapState({
      sources: state => state.source.sources,
      statuses: state => state.status.statuses,
      users: state => state.user.users
    })
  },

  methods: {
    ...mapActions({
      updateTicket: "ticket/updateTicket"
    }),

    handleChange(type) {
      switch (type) {
        case "status_id":
          this.params.status = document.getElementById(type).value;
          break;
        default:
          this.params[type] = document.getElementById(type).value;
          break;
      }

      this.params.id = this.ticket.id;
    },

    confirmUpdate() {
      this.updateTicket(this.params)
        .then(() => {
          this.params = {};
          this.loadTicket(this.ticket.id);
        })
        .catch(() => {
          this.params = {};
        });
    }
  }
};
</script>

<style lang="scss">
.title-selects {
  display: flex;

  &__item {
    display: flex;
    flex-direction: column;
    margin-right: 10px;

    label {
      position: relative;
      margin-bottom: 0;

      svg {
        position: absolute;
        right: 15px;
        top: 15px;
      }

      select {
        height: 33px;
        padding: 0 14px;
        border-radius: 4px;
        border: none;
        outline: none;
        font-size: 12px;
        width: 133px;
        appearance: none;
        font-weight: 600;
      }
    }
  }

  &__item--first {
    label {
      svg {
        path {
          stroke: #fba84c;
        }
      }
    }

    select {
      color: #fba84c;
      background-color: #fff3de;
    }
  }

  &__item--second {
    label {
      svg {
        path {
          stroke: $green-main;
        }
      }
    }

    select {
      color: $green-main;
      background-color: $green-second;
    }
  }

  &__item--third {
    label {
      svg {
        path {
          stroke: $main;
        }
      }
    }

    select {
      color: $main;
      background-color: $third;
    }
  }

  &__confirm {
    border: none;
    border-radius: 4px;
    outline: none;
    padding: 0 14px;
    color: $label-four;
    background-color: $main;
    font-size: 14px;
  }
}
</style>
